export const addPeer = (peer) => ({
  type: "ADD_PEER",
  payload: { peer },
});

export const removePeer = (peerId) => ({
  type: "REMOVE_PEER",
  payload: { peerId },
});

export const setPeerDisplayName = (displayName, peerId) => ({
  type: "SET_PEER_DISPLAY_NAME",
  payload: { displayName, peerId },
});

export const setPeerVideoInProgress = (peerId, flag) => ({
  type: "SET_PEER_VIDEO_IN_PROGRESS",
  payload: { peerId, flag },
});

export const setPeerAudioInProgress = (peerId, flag) => ({
  type: "SET_PEER_AUDIO_IN_PROGRESS",
  payload: { peerId, flag },
});

export const setPeerScreenInProgress = (peerId, flag) => ({
  type: "SET_PEER_SCREEN_IN_PROGRESS",
  payload: { peerId, flag },
});

export const setPeerRaiseHandState = (
  peerId,
  raiseHandState,
  raiseHandIndex
) => ({
  type: "SET_PEER_RAISE_HAND_STATE",
  payload: { peerId, raiseHandState, raiseHandIndex },
});

export const setPeerPicture = (peerId, picture) => ({
  type: "SET_PEER_PICTURE",
  payload: { peerId, picture },
});

export const addPeerRole = (peerId, role) => ({
  type: "ADD_PEER_ROLE",
  payload: { peerId, role },
});

export const removePeerRole = (peerId, role) => ({
  type: "REMOVE_PEER_ROLE",
  payload: { peerId, role },
});

export const setPeerKickInProgress = (peerId, flag) => ({
  type: "SET_PEER_KICK_IN_PROGRESS",
  payload: { peerId, flag },
});

export const toggleScreenPin = (peerId, screenPin, screenPinIndex) => ({
  type: "TOGGLE_SCREEN_PIN",
  payload: { peerId, screenPin, screenPinIndex },
});

export const setNativeLanguage = (peerId, nativeLanguage) => ({
  type: "SET_PEER_NATIVE_LANGUAGE",
  payload: { peerId, nativeLanguage },
});

export const removeAllNativeLanguage = () => ({
  type: "REMOVE_ALL_PEER_NATIVE_LANGUAGE",
});

export const setVisibility = (peerId, visibility) => ({
  type: "SET_PEER_VISIBILITY",
  payload: { peerId, visibility },
});
