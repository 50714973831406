export function createNewMessage(
  text,
  sender,
  name,
  picture,
  recipient,
  recipientDisplayName,
  senderId,
  isUnread,
  fileId,
  fileName
) {
  return {
    type: "message",
    text,
    time: Date.now(),
    name,
    sender,
    picture,
    recipient,
    recipientDisplayName,
    senderId,
    isUnread,
    fileId,
    fileName,
  };
}
