export const setData = (data) => ({
  type: "SET_DATA",
  payload: { data },
});

export const toggleWhiteBoard = () => ({
  type: "TOGGLE_WHITEBOARD",
});

export const removeWhiteBoardHasChanges = () => ({
  type: "REMOVE_WHITEBOARD_HAS_CHANGES",
});
