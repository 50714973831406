const initialState = {
  whiteBoardOpen: false,
  whiteBoardHasChanges: false,
  data: null,
};

const whiteboard = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA": {
      const { data } = action.payload;
      let whiteBoardHasChanges = true;
      if (state.whiteBoardOpen) whiteBoardHasChanges = false;
      return { ...state, data, whiteBoardHasChanges };
    }
    case "TOGGLE_WHITEBOARD": {
      const whiteBoardOpen = !state.whiteBoardOpen;
      let whiteBoardHasChanges = state.whiteBoardHasChanges;
      if (whiteBoardOpen) whiteBoardHasChanges = false;
      return { ...state, whiteBoardOpen, whiteBoardHasChanges };
    }
    case "REMOVE_WHITEBOARD_HAS_CHANGES": {
      const whiteBoardHasChanges = false;
      return { ...state, whiteBoardHasChanges };
    }

    default:
      return state;
  }
};

export default whiteboard;
