import JoinSteps from "../model/JoinSteps";

const initialState = {
  loginInProgress: false,
  joinStep: JoinSteps.LOGIN,
  guestCaptcha: null,
  authCaptcha: null,
  showLogin: true,
  isGuest: false,
  meetingInvitationEnabled: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_TOKEN": {
      return { ...state, token: action.payload.token };
    }
    case "SET_USER": {
      return { ...state, me: action.payload.user };
    }
    case "SET_LOGIN_PROGRESS": {
      return { ...state, loginInProgress: action.payload.inProgress };
    }
    case "SET_JOIN_STEP": {
      const { joinStep } = action.payload;
      if (!Object.values(JoinSteps.valueOf()).includes(joinStep))
        return { ...state };

      return { ...state, joinStep };
    }
    case "SET_GUEST_CAPTCHA": {
      return { ...state, guestCaptcha: action.payload.guestCaptcha };
    }
    case "SET_SHOW_LOGIN": {
      return { ...state, showLogin: action.payload.showLogin };
    }
    case "SET_AUTH_CAPTCHA": {
      return { ...state, authCaptcha: action.payload.authCaptcha };
    }
    case "SET_IS_GUEST": {
      return { ...state, isGuest: action.payload.isGuest };
    }
    case "SET_MEETING_INVITATION_ENABLED": {
      return {
        ...state,
        meetingInvitationEnabled: action.payload.meetingInvitationEnabled,
      };
    }
    default:
      return state;
  }
};

export default user;
