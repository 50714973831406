const initialState = {
  displayName: "Katılımcı",
  selectedWebcam: null,
  selectedAudioDevice: null,
  advancedMode: false,
  resolution: "low", // verylow, low, medium, high, veryhigh, ultra
  lastN: 20,
  virtualClassroom: false,
  permanentTopBar: false,
  whiteboardSound: false,
  chatSound: false,
  optimizeScreenShareForVideo: false,
  mirrorOwnImage: true,
  webcamAspectRatio: window.config.webcamAspectRatio || 1.333, // 4:3
  participantListVisibility: true,
  // explicitConsentApproval: null,
  newPeerSound: true,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_WEBCAM": {
      return { ...state, selectedWebcam: action.payload.deviceId };
    }

    case "CHANGE_AUDIO_DEVICE": {
      return { ...state, selectedAudioDevice: action.payload.deviceId };
    }

    case "CHANGE_AUDIO_OUTPUT_DEVICE": {
      return { ...state, selectedAudioOutputDevice: action.payload.deviceId };
    }

    case "SET_DISPLAY_NAME": {
      const { displayName } = action.payload;
      return { ...state, displayName };
    }

    case "TOGGLE_ADVANCED_MODE": {
      const advancedMode = !state.advancedMode;

      return { ...state, advancedMode };
    }

    case "SET_LAST_N": {
      const { lastN } = action.payload;

      return { ...state, lastN };
    }

    case "SET_TOTAL_N": {
      const { totalN } = action.payload;

      return { ...state, totalN };
    }

    case "TOGGLE_PERMANENT_TOPBAR": {
      const { permanentTopBar } = action.payload;

      return { ...state, permanentTopBar };
    }

    case "SET_VIDEO_RESOLUTION": {
      const { resolution } = action.payload;

      return { ...state, resolution };
    }

    case "SET_CHAT_SOUND": {
      const { chatSound } = action.payload;

      return { ...state, chatSound };
    }

    case "SET_VIRTUAL_CLASSROOM": {
      const { virtualClassroom } = action.payload;

      return { ...state, virtualClassroom };
    }

    case "SET_WHITEBOARD_SOUND": {
      const { whiteboardSound } = action.payload;

      return { ...state, whiteboardSound };
    }

    case "SET_SCREEN_SHARE_OPTIMIZED_FOR_VIDEO": {
      const { optimizeScreenShareForVideo } = action.payload;

      return { ...state, optimizeScreenShareForVideo };
    }

    case "SET_MIRROR_OWN_IMAGE": {
      const mirrorOwnImage = !state.mirrorOwnImage;
      return { ...state, mirrorOwnImage };
    }

    case "SET_WEBCAM_ASPECT_RATIO": {
      const webcamAspectRatio = action.payload.aspectRatio;
      return { ...state, webcamAspectRatio };
    }

    case "SET_PARTICIPANT_LIST_VISIBILITY": {
      const participantListVisibility =
        action.payload.participantListVisibility;
      return { ...state, participantListVisibility };
    }

    /*    case "SET_EXPLICIT_CONSENT_APPROVAL": {
      const { explicitConsentApproval } = action.payload;
      return { ...state, explicitConsentApproval };
    }*/

    case "SET_NEW_PEER_SOUND": {
      const { newPeerSound } = action.payload;

      return { ...state, newPeerSound };
    }
    default:
      return state;
  }
};

export default settings;
