import domready from "domready";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import isElectron from "is-electron";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import randomString from "random-string";
import Logger from "./Logger";
import debug from "debug";
import RoomClient from "./RoomClient";
import RoomContext from "./RoomContext";
import deviceInfo from "./deviceInfo";
import * as meActions from "./actions/meActions";
import ChooseRoom from "./components/ChooseRoom";
import LoadingView from "./components/LoadingView";

import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./store";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import { ReactLazyPreload } from "./components/ReactLazyPreload";
import ApiServiceClient from "./api/ApiServiceClient";
import Cookies from "js-cookie";

import messagesTurkish from "./translations/tr";
import messagesRussia from "./translations/ru";
import messagesArabic from "./translations/ar";

import "./index.css";
import DynamicThemeProvider from "./DynamicThemeProvider";

const App = ReactLazyPreload(() =>
  import(/* webpackChunkName: "app" */ "./components/App")
);

const cache = createIntlCache();

const messages = {
  // 'en' : messagesEnglish,
  tr: messagesTurkish,
  ru: messagesRussia,
  ar: messagesArabic,
};

const locale = navigator.language.split(/[-_]/)[0]; // language without region code

const intl = createIntl(
  {
    locale,
    messages: messages[locale],
  },
  cache
);

if (
  process.env.REACT_APP_DEBUG === "*" ||
  process.env.NODE_ENV !== "production"
) {
  debug.enable("* -engine* -socket* -RIE* *WARN* *ERROR*");
}

const logger = new Logger();

let roomClient;
let apiServiceClient;

RoomClient.init({ store, intl });

let Router;

if (isElectron()) Router = HashRouter;
else Router = BrowserRouter;

domready(() => {
  logger.debug("DOM ready");

  run();
});

function run() {
  logger.debug("run() [environment:%s]", process.env.NODE_ENV);

  const peerId = randomString({ length: 8 }).toLowerCase();
  const urlParser = new URL(window.location);
  const parameters = urlParser.searchParams;

  const accessCode = parameters.get("code");
  const produce = parameters.get("produce") !== "false";
  const useSimulcast = parameters.get("simulcast") === "true";
  const useSharingSimulcast = parameters.get("sharingSimulcast") === "true";
  const forceTcp = parameters.get("forceTcp") === "true";
  const displayName = parameters.get("displayName");
  const muted = parameters.get("muted") === "true";
  const userToken = Cookies.get("token");

  // Get current device.
  const device = deviceInfo();

  store.dispatch(
    meActions.setMe({
      peerId,
      loginEnabled: window.config.loginEnabled,
    })
  );

  roomClient = new RoomClient({
    peerId,
    accessCode,
    device,
    useSimulcast,
    useSharingSimulcast,
    produce,
    forceTcp,
    displayName,
    muted,
    userToken,
  });

  global.CLIENT = roomClient;
  ApiServiceClient.init(store, roomClient);
  apiServiceClient = new ApiServiceClient();

  render(
    <Provider store={store}>
      <DynamicThemeProvider>
        <RawIntlProvider value={intl}>
          <PersistGate loading={<LoadingView />} persistor={persistor}>
            <RoomContext.Provider value={{ roomClient, apiServiceClient }}>
              <SnackbarProvider>
                <Router>
                  <Suspense fallback={<LoadingView />}>
                    <React.Fragment>
                      <Route exact path="/" component={ChooseRoom} />
                      <Route
                        path={
                          window.config.customPath
                            ? `/${window.config.customPath}/:id`
                            : `/:id`
                        }
                        component={App}
                      />
                    </React.Fragment>
                  </Suspense>
                </Router>
              </SnackbarProvider>
            </RoomContext.Provider>
          </PersistGate>
        </RawIntlProvider>
      </DynamicThemeProvider>
    </Provider>,
    document.getElementById("conference-element")
  );
}

serviceWorker.unregister();
