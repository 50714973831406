import axios from "axios";
import * as userAction from "../actions/userActions";
import * as meActions from "../actions/meActions";
import * as roomActions from "../actions/roomActions";
import JoinSteps from "../model/JoinSteps";
import Logger from "../Logger";
import * as Logout from "../Logout";
import * as settingsActions from "../actions/settingsActions";
const crypto = require("crypto");

const logger = new Logger("ApiServiceClient");
let url = window.config.apiServiceUrl;
const algorithm = window.config.alg;
const vector = window.config.iv;
const key = window.config.key;
let store;
let roomClient;

export default class ApiServiceClient {
  static init(storeObj, roomClientObj) {
    store = storeObj;
    roomClient = roomClientObj;
  }

  loginWithCaptcha(username, password, roomId, captchaId, response) {
    axios
      .get(url + "/captcha/check?id=" + captchaId + "&response=" + response)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.login(username, password, roomId);
          } else {
            store.dispatch(userAction.setAuthCaptcha(response.data.captcha));
            roomClient.dispatchNotification(
              "captcha.wrong",
              "error",
              "The security code could not be verified."
            );
          }
        }
      });
  }

  goToScreen(joinStep) {
    store.dispatch(userAction.setJoinStep(joinStep));
  }

  goToPreviousScreen(currentScreen) {
    switch (currentScreen) {
      case JoinSteps.MEETING_INVITATION:
        this.goToScreen(JoinSteps.ROOM_OR_MEETING_INVITATION);
        break;

      case JoinSteps.ROOM_OR_MEETING_INVITATION:
        this.goToScreen(JoinSteps.LOGIN);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("mfaVerified");
        Logout.endSession();
        roomClient.receiveLogoutChildWindow();
        break;

      case JoinSteps.PRODUCER_SELECTION:
        this.goToScreen(JoinSteps.ROOM_OR_MEETING_INVITATION);
        break;

      default:
    }
  }

  sendMeetingInvitation(meetingTime, mailSubject, roomId) {
    const eMail = sessionStorage.getItem("username");

    axios
      .post(url + "/sendMeetingInvitation", {
        eMail: eMail,
        meetingTime: meetingTime,
        mailSubject: mailSubject,
        roomName: roomId,
      })
      .then((response) => {
        if (response.status === 200) {
          roomClient.dispatchNotification(
            "invitation.sendMeetingInvitation",
            "info",
            "The meeting invitation has been sent."
          );
        }
      })
      .catch((reason) => {
        if (reason.response.status === 406) {
          roomClient.dispatchNotification(
            "invitation.expired",
            "error",
            "The time for the meeting is in the past."
          );
        } else {
          roomClient.dispatchNotification(
            "invitation.sendMeetingInvitationProblem",
            "error",
            "The meeting invitation could not be sent."
          );
        }
      });
  }

  /*  async setClarificationTextApproved(clarificationTextApproved) {
    sessionStorage.setItem(
      "clarificationTextApproved",
      clarificationTextApproved.toString()
    );

    axios
      .post(url + "/setClarificationTextApproved", {
        peerId: roomClient._peerId,
        name: sessionStorage.getItem("name"),
        clarificationTextApproved: clarificationTextApproved,
      })
      .then((response) => {
        if (response.status === 200) {
          roomClient.dispatchNotification(
            "clarification.setClarificationTextApproved",
            "info",
            "The clarification text has been approved."
          );
        }
      })
      .catch(() => {
        roomClient.dispatchNotification(
          "clarification.setClarificationTextApprovedProblem",
          "error",
          "The clarification text has not been approved."
        );
      });
  }*/

  login(username, password, roomId) {
    store.dispatch(userAction.setLoginProgress(true));

    if(this.checkEmailRegex(username)) {
      axios.post(url + "/login?roomId=" + roomId, {
        username: username,
        password: password,
        peerId: roomClient._peerId,
        roomId: roomId,
      }).then((response) => {
        if (response.status === 200) {
          sessionStorage.removeItem("token");
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("user", response.data);
          store.dispatch(userAction.setUser(response.data));
          response.data.roles.forEach((role) => {
            store.dispatch(meActions.addRole(role));
          });

          store.dispatch(
            userAction.setMeetingInvitationEnabled(
              response.data.meetingInvitationEnabled
            )
          );
          sessionStorage.setItem(
            "meetingInvitationEnabled",
            response.data.meetingInvitationEnabled.toString()
          );

          /*          let clarificationTextOpen = !response.data.clarificationTextApproved;
          store.dispatch(
            roomActions.setClarificationTextOpen({ clarificationTextOpen })
          );

          sessionStorage.setItem(
            "clarificationTextOpen",
            clarificationTextOpen.toString()
          );

          sessionStorage.setItem("name", response.data.name);
          sessionStorage.setItem("meta", response.data.meta);

          console.log(
            "UI clarificationTextApproved: " +
              response.data.clarificationTextApproved
          );*/

          if (response.data.mfaEnabled) {
            const { displayName, picture } = response.data;
            store.dispatch(settingsActions.setDisplayName(displayName));
            store.dispatch(meActions.setPicture(picture));
            store.dispatch(meActions.loggedIn(true));

            this.goToScreen(JoinSteps.SMS);
          } else {
            roomClient.receiveLoginChildWindow(response.data);
            sessionStorage.setItem("mfaEnabled", "false");
          }

          if (response.data.roomSpec.webinar)
            store.dispatch(roomActions.setRoomWebinar());

          sessionStorage.setItem("username", username);
        } else {
          roomClient.dispatchNotification(
            "user.wrongEmailOrPassword",
            "error",
            "Wrong email or password"
          );
        }
        store.dispatch(userAction.setLoginProgress(false));
      }).catch((reason) => {
        if (
          reason &&
          reason.response &&
          reason.response.data &&
          reason.response.data.captcha
        ) {
          store.dispatch(
            userAction.setAuthCaptcha(reason.response.data.captcha)
          );
        }
        roomClient.dispatchNotification(
          "user.wrongEmailOrPassword",
          "error",
          "Wrong email or password"
        );
        store.dispatch(userAction.setLoginProgress(false));
      });
    }
    else {
      roomClient.dispatchNotification(
        "user.invalidEmail",
        "error",
        "Invalid email address"
      );
    }
    store.dispatch(userAction.setLoginProgress(false));
  }

  checkEmailRegex(email) {
    const regex = new RegExp(
      /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
    );
    return regex.test(email);
  }

  checkAuth(token, roomId) {
    store.dispatch(userAction.setLoginProgress(true));
    axios
      .post(url + "/checkAuth?roomId=" + roomId, {
        token: token,
        peerId: roomClient._peerId,
        roomId: roomId,
      })
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.removeItem("token");
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("user", response.data);
          store.dispatch(userAction.setUser(response.data));
          response.data.roles.forEach((role) => {
            store.dispatch(meActions.addRole(role));
          });
          if (
            sessionStorage.getItem("mfaVerified") === "completed" ||
            sessionStorage.getItem("mfaEnabled") === "false"
          ) {
            roomClient.receiveLoginChildWindow(response.data);
          } else {
            this.goToScreen(JoinSteps.SMS);
          }
          if (response.data.roomSpec.webinar)
            store.dispatch(roomActions.setRoomWebinar());
        } else {
          if (response.data.roomSpec.webinar)
            store.dispatch(roomActions.setRoomWebinar());
        }
        store.dispatch(userAction.setLoginProgress(false));
      })
      .catch((reason) => {
        store.dispatch(userAction.setLoginProgress(false));
      });
  }

  mfa(otp_code) {
    const token = sessionStorage.getItem("token").toString();
    axios
      .post(url + "/mfa", {
        otp_code: otp_code,
        token: token,
      })
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("mfaVerified", "completed");

          if (sessionStorage.getItem("meetingInvitationEnabled") === "true") {
            this.goToScreen(JoinSteps.ROOM_OR_MEETING_INVITATION);
          } else {
            this.goToScreen(JoinSteps.PRODUCER_SELECTION);
          }
        } else {
          this.goToScreen(JoinSteps.SMS);
          roomClient.dispatchNotification(
            "user.wrongEmailOrPassword",
            "error",
            "Wrong OTP Code"
          );
        }
      })
      .catch((e) => {
        logger.error(e.message);
        this.goToScreen(JoinSteps.SMS);
        roomClient.dispatchNotification(
          "mfa.wrongOTPCode",
          "error",
          "Wrong OTP Code"
        );
      });
  }

  resendSMSRequest() {
    axios
      .post(url + "/resendSMS", {
        token: sessionStorage.getItem("token").toString(),
      })
      .then((response) => {
        if (response.status === 200) {
          if (sessionStorage.getItem("meetingInvitationEnabled") === "true") {
            this.goToScreen(JoinSteps.ROOM_OR_MEETING_INVITATION);
          } else {
            this.goToScreen(JoinSteps.PRODUCER_SELECTION);
          }
        } else {
          logger.error("Resend SMS request could not sent");
        }
      });
  }

  createCaptcha(isGuest = true) {
    axios.get(url + "/captcha/create").then((response) => {
      store.dispatch(userAction.setGuestCaptcha(response.data));
    });
  }

  decryptText(text) {
    const decipher = crypto.createDecipheriv(algorithm, key, vector);
    return decipher.update(text, "hex", "utf8") +
      decipher.final("utf8").toString();
  }

  checkCaptcha(id, response) {
    axios
      .get(url + "/captcha/check?id=" + id + "&response=" + response)
      .then((res) => {
          if (res.status === 200 && res.data.status && this.decryptText(res.data.text) === response) {
            store.dispatch(userAction.setShowLogin(false));
            this.goToScreen(JoinSteps.PRODUCER_SELECTION);
          } else {
            store.dispatch(userAction.setGuestCaptcha(res.data.captcha));
            roomClient.dispatchNotification(
              "captcha.wrong",
              "error",
              "The security code could not be verified."
            );
          }
      });
  }

  hasRoomPassword(roomId) {
    axios
      .get(url + "/hasRoomPassword?roomId=" + roomId)
      .then((response) => {
        store.dispatch(roomActions.setHasPassword(response.data));
      })
      .catch((reason) => {
        store.dispatch(roomActions.setHasPassword(false));
      });
  }

  checkRoomPassword(password, roomId) {
    axios
      .post(url + "/checkRoomPassword", { password, roomId })
      .then((response) => {
        if (response.data) {
          store.dispatch(roomActions.setValidatePassword(true));
        } else {
          store.dispatch(roomActions.setValidatePassword(false));
          roomClient.dispatchNotification(
            "room.wrongRoomPassword",
            "error",
            "Wrong room password"
          );
        }
      })
      .catch((reason) => {
        store.dispatch(roomActions.setValidatePassword(false));
        roomClient.dispatchNotification(
          "room.roomPasswordRequestError",
          "error",
          "Check room password request error!"
        );
      });
  }

  getUniqueDisplayName = (displayName, roomId) =>
    axios
      .post(url + "/uniqueDisplayName", {
        displayName,
        roomId,
      })
      .then(({ data: { uniqueDisplayName } }) => uniqueDisplayName);
}
