import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import randomString from "random-string";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.backgroundColor.primary,
    backgroundImage: `url(${theme.images.background})`,
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  bottomLogo: {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    bottom: "10%",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "5px",
    },
  },
  allRightsReservedLogo: {
    position: "absolute",
    left: "74%",
    right: "0",
    marginLeft: "auto",
    marginRight: "1%",
    bottom: "1%",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ebebeb",
  },
  backDrop: {
    display: "none",
  },
  dialogPaper: {
    backgroundColor: "rgba(249,249,249,0)",
    //backgroundColor : 'red',
    boxShadow: "none",
    width: "25vw",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      width: "25vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "35vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "55vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "75vw",
    },
  },
  contentTextColor: {
    color: theme.textField.textColor,
    fontSize: 14,
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.31,
    letterSpacing: 0.26,
  },
  roomName: {
    width: 270,
    fontSize: 14,
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.31,
    letterSpacing: 0.26,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.textField.borderBottomColorBefore,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.textField.borderBottomColorAfter,
    },
  },
  brand: {
    textShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    fontSize: 20,
    fontWeight: 300,
    width: 240,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: 0.79,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: -5,
  },
  appName: {
    textShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    fontSize: 53,
    width: 240,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: 0.79,
    textAlign: "left",
    color: "rgba(255, 255, 255, 0.77)",
    marginBottom: 20,
  },
  button: {
    alignSelf: "center",
    width: 150,
    minWidth: 150,
    borderRadius: 20,
    color: "#333",
    boxShadow: "0 2px 3px 0 rgba(84, 84, 84, 0.36)",
    backgroundColor: "#c9ced4",
    textTransform: "none",
    marginTop: 20,
  },
  brandRoot: {
    textAlign: "-webkit-center",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h5">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
let version;
if (process.env.NODE_ENV !== "test") {
  ({ version } = window.config);
} else version = "test_version";

const ChooseRoom = ({ theme, classes }) => {
  const [roomId, setRoomId] = useState(
    randomString({ length: 8 }).toLowerCase()
  );

  const intl = useIntl();

  return (
    <div className={classes.root}>
      <Dialog
        open
        classes={{
          paper: classes.dialogPaper,
        }}
        BackdropProps={{ classes: { root: classes.backDrop } }}
      >
        {/*				<DialogTitle>
					{ window.config && window.config.title ? window.config.title : 'Uçurtma' }
				</DialogTitle>*/}
        {theme.images.brandNameImage && (
          <img
            id={"brandNameImage"}
            src={theme.images.brandNameImage}
            style={{ width: "100%" }}
          />
        )}

        {
          <div
            style={{
              height: "115px",
              textAlign: "center",
              display: "block",
            }}
          >
            {theme.images.humanDiscussion && (
              <img
                id={"humanDiscussionImage"}
                src={theme.images.humanDiscussion}
              />
            )}
          </div>
        }

        <DialogContent>
          <DialogContentText gutterBottom className={classes.contentTextColor}>
            <FormattedMessage
              tagName={"chooseroom"}
              id="room.chooseRoom"
              defaultMessage="Type the name of the room you would like to join"
            />
          </DialogContentText>

          <TextField
            id="roomId"
            className={classes.roomName}
            label={intl.formatMessage({
              id: "label.roomName",
              defaultMessage: "Room name",
            })}
            value={roomId}
            variant="standard"
            margin="normal"
            color={"secondary"}
            inputProps={{
              maxLength: 75,
            }}
            InputProps={{
              className: classes.contentTextColor,
              style: { color: theme.textField.textColor },
            }}
            InputLabelProps={{
              className: classes.contentTextColor,
              style: { color: theme.textField.labelColor },
            }}
            onChange={(event) => {
              const re = /^[a-zA-Z0-9\b]*$/;
              const { value } = event.target;
              if (re.test(value))
                setRoomId(value.replace(/%/g, "").toLowerCase());
            }}
            onBlur={() => {
              if (roomId.trim() === "")
                setRoomId(randomString({ length: 8 }).toLowerCase());
            }}
            fullWidth
          />
        </DialogContent>

        <Button
          className={classes.button}
          component={Link}
          to={
            window.config.customPath
              ? `${window.config.customPath}/${roomId}`
              : roomId
          }
          variant="contained"
          color={"secondary"}
        >
          <FormattedMessage
            id="label.chooseRoomButton"
            defaultMessage="Continue"
          />
        </Button>

        {/*				{ !isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
				}*/}
        <br />
        <br />
      </Dialog>
      {theme.images.bottomLogo && (
        <img
          id="logo"
          alt="Logo"
          className={classes.bottomLogo}
          src={theme.images.bottomLogo}
        />
      )}
      {
        <img
          id="logo"
          alt="Logo"
          className={classes.allRightsReservedLogo}
          src={theme.images.allRightsReservedLogo}
        />
      }
    </div>
  );
};

ChooseRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    theme: state.theme.defaultTheme,
  };
}

export default connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    return prev.theme.defaultTheme === next.theme.defaultTheme;
  },
})(withStyles(styles)(ChooseRoom));
