const initialState = {
  toolAreaOpen: false,
  currentToolTab: "chat", // chat, settings, users
  currentChatRecipient: "everyone",
  unreadPublicMessages: 0,

  unreadFiles: 0,
};

const toolarea = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_TOOL_AREA": {
      const toolAreaOpen = !state.toolAreaOpen;

      const unreadPublicMessages =
        state.currentToolTab !== "chat"
          ? state.unreadPublicMessages
          : state.currentToolTab === "chat" &&
            state.currentChatRecipient === "everyone"
          ? 0
          : state.unreadPublicMessages;

      const unreadFiles =
        toolAreaOpen && state.currentToolTab === "files"
          ? 0
          : state.unreadFiles;

      const currentChatRecipient =
        state.toolAreaOpen && state.currentToolTab === "chat"
          ? "everyone"
          : state.currentChatRecipient;

      const currentToolTab = state.currentToolTab;
      return {
        ...state,
        toolAreaOpen: toolAreaOpen,
        currentToolTab: currentToolTab,
        currentChatRecipient: currentChatRecipient,
        unreadPublicMessages: unreadPublicMessages,
        unreadFiles: unreadFiles,
      };
    }

    case "OPEN_TOOL_AREA": {
      const toolAreaOpen = true;

      const unreadPublicMessages = state.unreadPublicMessages;
      const unreadFiles =
        state.currentToolTab === "files" ? 0 : state.unreadFiles;
      const currentChatRecipient = state.currentChatRecipient;
      const currentToolTab = state.currentToolTab;
      return {
        ...state,
        toolAreaOpen,
        currentToolTab,
        currentChatRecipient,
        unreadPublicMessages,
        unreadFiles,
      };
    }

    case "CLOSE_TOOL_AREA": {
      const toolAreaOpen = false;

      return { ...state, toolAreaOpen };
    }

    case "SET_TOOL_TAB": {
      const { toolTab } = action.payload;
      const unreadPublicMessages =
        toolTab !== "chat"
          ? state.unreadPublicMessages
          : state.currentToolTab === "chat" &&
            state.currentChatRecipient === "everyone"
          ? 0
          : state.unreadPublicMessages;

      const unreadFiles = toolTab === "files" ? 0 : state.unreadFiles;

      return {
        ...state,
        currentToolTab: toolTab,
        unreadPublicMessages: unreadPublicMessages,
        unreadFiles: unreadFiles,
      };
    }

    case "SET_CHAT_RECIPIENT": {
      const { chatRecipient } = action.payload;
      return { ...state, currentChatRecipient: chatRecipient };
    }

    case "ADD_NEW_RESPONSE_MESSAGE": {
      if (state.toolAreaOpen && state.currentToolTab === "chat") {
        return state;
      }

      return { ...state, unreadPublicMessages: state.unreadPublicMessages + 1 };
    }

    case "ADD_NEW_BATCH_RESPONSE_MESSAGE":
    case "ADD_CHAT_HISTORY": {
      /* if (state.toolAreaOpen && state.currentToolTab === 'chat') {
         return state;
       }
       */
      return {
        ...state,
        unreadPublicMessages:
          state.unreadPublicMessages +
          Object.values(action.payload)[0].filter(
            (m) => m.recipient === "everyone"
          ).length,
      };
    }

    case "ADD_FILE": {
      if (state.toolAreaOpen && state.currentToolTab === "files") {
        return state;
      }

      return { ...state, unreadFiles: state.unreadFiles + 1 };
    }

    default:
      return state;
  }
};

export default toolarea;
