export const setUserToken = (token) => ({
  type: "SET_USER_TOKEN",
  payload: { token },
});

export const setUser = (user) => ({
  type: "SET_USER",
  payload: { user },
});

export const setLoginProgress = (inProgress) => ({
  type: "SET_LOGIN_PROGRESS",
  payload: { inProgress },
});

export const setJoinStep = (joinStep) => ({
  type: "SET_JOIN_STEP",
  payload: { joinStep },
});

export const setGuestCaptcha = (guestCaptcha) => ({
  type: "SET_GUEST_CAPTCHA",
  payload: { guestCaptcha },
});

export const setShowLogin = (showLogin) => ({
  type: "SET_SHOW_LOGIN",
  payload: { showLogin },
});

export const setAuthCaptcha = (authCaptcha) => ({
  type: "SET_AUTH_CAPTCHA",
  payload: { authCaptcha },
});

export const setIsGuest = (isGuest) => ({
  type: "SET_IS_GUEST",
  payload: { isGuest },
});
export const setMeetingInvitationEnabled = (meetingInvitationEnabled) => ({
  type: "SET_MEETING_INVITATION_ENABLED",
  payload: { meetingInvitationEnabled },
});
