import { createNewMessage } from "./helper";

const chat = (state = [], action) => {
  switch (action.type) {
    case "ADD_NEW_USER_MESSAGE": {
      const {
        text,
        displayName,
        recipient,
        recipientDisplayName,
        senderId,
        isUnread,
        fileId,
        fileName,
      } = action.payload;

      const message = createNewMessage(
        text,
        "client",
        displayName,
        undefined,
        recipient,
        recipientDisplayName,
        senderId,
        isUnread,
        fileId,
        fileName
      );

      return [...state, message];
    }

    case "ADD_NEW_RESPONSE_MESSAGE": {
      const { message } = action.payload;

      return [...state, message];
    }

    case "ADD_CHAT_HISTORY": {
      const { chatHistory } = action.payload;

      return [...state, ...chatHistory];
    }

    case "ADD_NEW_BATCH_RESPONSE_MESSAGE": {
      const { messages } = action.payload;
      let chatMessages = [...state];
      Object.values(messages).forEach((m) => chatMessages.push(m));
      return chatMessages;
    }
    case "MARK_UNREAD_MESSAGE_AS_READ": {
      const { senderId } = action.payload;
      let chatMessages = [...state];

      const newChatState = chatMessages.map((message) =>
        message.senderId === senderId
          ? { ...message, isUnread: false }
          : message
      );
      return newChatState;
    }

    default:
      return state;
  }
};

export default chat;
