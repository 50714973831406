const initialState = {
  themes: [],
  defaultTheme: null,
};

const theme = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_THEMES": {
      return { ...state, themes: action.payload.themes };
    }
    case "ADD_DEFAULT_THEME": {
      return { ...state, defaultTheme: action.payload.defaultTheme };
    }
    case "CHANGE_DEFAULT_THEME": {
      const { themeId } = action.payload;
      const theme = state.themes.find((theme) => theme.id === themeId);
      if (theme) return { ...state, defaultTheme: theme };
      return state;
    }
  }
  return state;
};

export default theme;
