import axios from "axios";
import Logger from "./Logger";

let roomClient;
const logger = new Logger("Logout");
let url = window.config.apiServiceUrl;

export function endSession() {
  const token = sessionStorage.getItem("token");
  if (token) {
    sessionStorage.removeItem("token");
    axios
      .post(url + "/logout", {
        token: token,
      })
      .then((response) => {
        if (response.status === 200) {
          logger.info("user logged out");
        }
      })
      .catch((e) => {
        logger.error("Error while closing session" + e.message);
        roomClient.dispatchNotification(
          "room.notLogout",
          "error",
          "User could not logout"
        );
      });
  }
}
