export const addUserMessage = (
  text,
  displayName,
  recipient,
  recipientDisplayName,
  senderId,
  fileId,
  fileName
) => ({
  type: "ADD_NEW_USER_MESSAGE",
  payload: {
    text,
    displayName,
    recipient,
    recipientDisplayName,
    senderId,
    fileId,
    fileName,
  },
});

export const addResponseMessage = (message) => ({
  type: "ADD_NEW_RESPONSE_MESSAGE",
  payload: { message },
});

export const addChatHistory = (chatHistory) => ({
  type: "ADD_CHAT_HISTORY",
  payload: { chatHistory },
});

export const addBatchResponseMessage = (messages) => ({
  type: "ADD_NEW_BATCH_RESPONSE_MESSAGE",
  payload: { messages },
});
export const markUnreadMessageAsRead = (senderId) => ({
  type: "MARK_UNREAD_MESSAGE_AS_READ",
  payload: { senderId },
});
