export const setRoomName = (name) => ({
  type: "SET_ROOM_NAME",
  payload: { name },
});

export const setRoomState = (state) => ({
  type: "SET_ROOM_STATE",
  payload: { state },
});

export const setRoomActiveSpeaker = (peerId) => ({
  type: "SET_ROOM_ACTIVE_SPEAKER",
  payload: { peerId },
});

export const setRoomLocked = () => ({
  type: "SET_ROOM_LOCKED",
});

export const setRoomUnLocked = () => ({
  type: "SET_ROOM_UNLOCKED",
});

export const setInLobby = (inLobby) => ({
  type: "SET_IN_LOBBY",
  payload: { inLobby },
});

export const setSignInRequired = (signInRequired) => ({
  type: "SET_SIGN_IN_REQUIRED",
  payload: { signInRequired },
});

export const setAccessCode = (accessCode) => ({
  type: "SET_ACCESS_CODE",
  payload: { accessCode },
});

export const setJoinByAccessCode = (joinByAccessCode) => ({
  type: "SET_JOIN_BY_ACCESS_CODE",
  payload: { joinByAccessCode },
});

export const setSettingsOpen = ({ settingsOpen }) => ({
  type: "SET_SETTINGS_OPEN",
  payload: { settingsOpen },
});

export const setClarificationTextOpen = ({ clarificationTextOpen }) => ({
  type: "SET_CLARIFICATION_TEXT_OPEN",
  payload: { clarificationTextOpen },
});

export const setLockDialogOpen = ({ lockDialogOpen }) => ({
  type: "SET_LOCK_DIALOG_OPEN",
  payload: { lockDialogOpen },
});

export const setLeaveDialogOpen = ({ leaveDialogOpen }) => ({
  type: "SET_LEAVE_DIALOG_OPEN",
  payload: { leaveDialogOpen },
});

export const setFileSharingSupported = (supported) => ({
  type: "FILE_SHARING_SUPPORTED",
  payload: { supported },
});

export const toggleConsumerWindow = (consumerId) => ({
  type: "TOGGLE_WINDOW_CONSUMER",
  payload: { consumerId },
});

export const setToolbarsVisible = (toolbarsVisible) => ({
  type: "SET_TOOLBARS_VISIBLE",
  payload: { toolbarsVisible },
});

export const setDisplayMode = (mode) => ({
  type: "SET_DISPLAY_MODE",
  payload: { mode },
});

export const setSelectedPeer = (selectedPeerId) => ({
  type: "SET_SELECTED_PEER",
  payload: { selectedPeerId },
});

export const setSpotlights = (spotlights) => ({
  type: "SET_SPOTLIGHTS",
  payload: { spotlights },
});

export const toggleJoined = () => ({
  type: "TOGGLE_JOINED",
});

export const toggleConsumerFullscreen = (consumerId) => ({
  type: "TOGGLE_FULLSCREEN_CONSUMER",
  payload: { consumerId },
});

export const setMuteAllInProgress = (flag) => ({
  type: "MUTE_ALL_IN_PROGRESS",
  payload: { flag },
});

export const setStopAllVideoInProgress = (flag) => ({
  type: "STOP_ALL_VIDEO_IN_PROGRESS",
  payload: { flag },
});

export const setCloseMeetingInProgress = (flag) => ({
  type: "CLOSE_MEETING_IN_PROGRESS",
  payload: { flag },
});

export const setUserRoles = (userRoles) => ({
  type: "SET_USER_ROLES",
  payload: { userRoles },
});

export const setPermissionsFromRoles = (permissionsFromRoles) => ({
  type: "SET_PERMISSIONS_FROM_ROLES",
  payload: { permissionsFromRoles },
});

export const setRoomWebinar = (flag) => ({
  type: "SET_ROOM_WEBINAR",
  payload: { flag },
});

export const setRoomRecordingState = (state) => ({
  type: "SET_ROOM_RECORDING_STATE",
  payload: { state },
});

export const setStartTime = (startTime) => ({
  type: "SET_START_TIME",
  payload: { startTime },
});

export const setRecordingNotificationStatus = (
  recordingNotificationStatus
) => ({
  type: "SET_RECORDING_NOTIFICATION_STATUS",
  payload: { recordingNotificationStatus },
});

export const setChangeModerator = (isDialogOpen, peerId) => ({
  type: "SET_CHANGE_MODERATOR",
  payload: { changeModerator: { isDialogOpen, peerId } },
});

export const setControlBarVisibility = (controlBarVisibility) => ({
  type: "SET_CONTROL_BAR_VISIBILITY",
  payload: { controlBarVisibility: controlBarVisibility },
});

export const setWhiteBoardEnabled = () => ({
  type: "SET_WHITEBOARD_ENABLED",
});

export const setMeetingSuspended = (meetingSuspended) => ({
  type: "SET_MEETING_SUSPENDED",
  payload: { meetingSuspended },
});

export const setInterpreterSupporting = (hasInterpreterSupporting) => ({
  type: "SET_INTERPRETER_SUPPORTING",
  payload: { hasInterpreterSupporting },
});

export const setInterpreterLanguageApproved = (
  isInterpreterLanguageApproved
) => ({
  type: "SET_INTERPRETER_LANGUAGE_APPROVED",
  payload: { isInterpreterLanguageApproved },
});

export const setInterpreters = (interpreters) => ({
  type: "SET_INTERPRETERS",
  payload: { interpreters },
});

export const setInterpreterActiveLanguage = (interpreterActiveLanguage) => ({
  type: "SET_INTERPRETER_ACTIVE_LANGUAGE",
  payload: { interpreterActiveLanguage },
});

export const setParticipantLog = (participantLog) => ({
  type: "SET_PARTICIPANT_LOG",
  payload: { participantLog },
});

export const setPassword = (password) => ({
  type: "SET_PASSWORD",
  payload: { password },
});

export const setHasPassword = (hasPassword) => ({
  type: "SET_HAS_PASSWORD",
  payload: { hasPassword },
});

export const setValidatePassword = (validatePassword) => ({
  type: "SET_VALIDATE_PASSWORD",
  payload: { validatePassword },
});

export const setRoomKey = (roomKey) => ({
  type: "SET_ROOM_KEY",
  payload: { roomKey },
});
