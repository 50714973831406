export const addThemes = (themes) => ({
  type: "ADD_THEMES",
  payload: { themes },
});

export const addDefaultTheme = (defaultTheme) => ({
  type: "ADD_DEFAULT_THEME",
  payload: { defaultTheme },
});

export const changeDefaultTheme = (themeId) => ({
  type: "CHANGE_DEFAULT_THEME",
  payload: { themeId },
});
