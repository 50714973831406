const peer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_PEER":
      return { ...action.payload.peer };

    case "SET_PEER_DISPLAY_NAME":
      return { ...state, displayName: action.payload.displayName };

    case "SET_PEER_VIDEO_IN_PROGRESS":
      return { ...state, peerVideoInProgress: action.payload.flag };

    case "SET_PEER_AUDIO_IN_PROGRESS":
      return { ...state, peerAudioInProgress: action.payload.flag };

    case "SET_PEER_SCREEN_IN_PROGRESS":
      return { ...state, peerScreenInProgress: action.payload.flag };

    case "SET_PEER_KICK_IN_PROGRESS":
      return { ...state, peerKickInProgress: action.payload.flag };

    case "SET_PEER_RAISE_HAND_STATE":
      return {
        ...state,
        raiseHandState: action.payload.raiseHandState,
        raiseHandIndex: action.payload.raiseHandIndex,
      };

    case "ADD_CONSUMER": {
      const consumers = [...state.consumers, action.payload.consumer.id];

      return { ...state, consumers };
    }

    case "ADD_DATA_CONSUMER": {
      if (!state.dataConsumers) {
        state.dataConsumers = [];
      }

      const dataConsumers = [
        ...state.dataConsumers,
        action.payload.dataConsumer.id,
      ];

      return { ...state, dataConsumers };
    }

    case "REMOVE_CONSUMER": {
      const consumers = state.consumers.filter(
        (consumer) => consumer !== action.payload.consumerId
      );

      return { ...state, consumers };
    }

    case "REMOVE_DATA_CONSUMER": {
      const dataConsumers = state.dataConsumers.filter(
        (dataConsumer) => dataConsumer !== action.payload.dataConsumerId
      );

      return { ...state, dataConsumers };
    }

    case "SET_PEER_PICTURE": {
      return { ...state, picture: action.payload.picture };
    }

    case "ADD_PEER_ROLE": {
      const roles = [...state.roles, action.payload.role];

      return { ...state, roles };
    }

    case "REMOVE_PEER_ROLE": {
      const roles = state.roles.filter((role) => role !== action.payload.role);

      return { ...state, roles };
    }

    case "TOGGLE_SCREEN_PIN": {
      return {
        ...state,
        screenPin: action.payload.screenPin,
        screenPinIndex: action.payload.screenPinIndex,
      };
    }

    case "SET_PEER_NATIVE_LANGUAGE": {
      return { ...state, nativeLanguage: action.payload.nativeLanguage };
    }
    case "REMOVE_ALL_PEER_NATIVE_LANGUAGE": {
      return { ...state, nativeLanguage: "" };
    }

    case "SET_PEER_VISIBILITY": {
      return { ...state, visibility: action.payload.visibility };
    }

    default:
      return state;
  }
};

const peers = (state = {}, action) => {
  switch (action.type) {
    case "ADD_PEER": {
      return { ...state, [action.payload.peer.id]: peer(undefined, action) };
    }

    case "REMOVE_ALL_PEER_NATIVE_LANGUAGE": {
      let newState = { ...state };

      Object.keys(state).forEach(
        (peerId) =>
          (newState = { ...newState, [peerId]: peer(state[peerId], action) })
      );
      return newState;
    }

    case "REMOVE_PEER": {
      const { peerId } = action.payload;
      const newState = { ...state };

      delete newState[peerId];

      return newState;
    }

    case "SET_PEER_DISPLAY_NAME":
    case "SET_PEER_VIDEO_IN_PROGRESS":
    case "SET_PEER_AUDIO_IN_PROGRESS":
    case "SET_PEER_SCREEN_IN_PROGRESS":
    case "SET_PEER_RAISE_HAND_STATE":
    case "SET_PEER_PICTURE":
    case "ADD_CONSUMER":
    case "ADD_DATA_CONSUMER":
    case "ADD_PEER_ROLE":
    case "REMOVE_PEER_ROLE":
    case "TOGGLE_SCREEN_PIN":
    case "SET_PEER_NATIVE_LANGUAGE":
    case "SET_PEER_VISIBILITY": {
      const oldPeer = state[action.payload.peerId];
      if (!oldPeer) {
        throw new Error("no Peer found");
      }

      return { ...state, [oldPeer.id]: peer(oldPeer, action) };
    }

    case "SET_PEER_KICK_IN_PROGRESS":
    case "REMOVE_CONSUMER": {
      const oldPeer = state[action.payload.peerId];

      // NOTE: This means that the Peer was closed before, so it's ok.
      if (!oldPeer) return state;

      return { ...state, [oldPeer.id]: peer(oldPeer, action) };
    }
    default:
      return state;
  }
};

export default peers;
