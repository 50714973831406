export function getSignalingUrl(peerId, roomId) {
  const hostname = window.config.serverUrl;

  const port =
    process.env.NODE_ENV !== "production"
      ? window.config.developmentPort
      : window.config.productionPort;

  return `wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}`;
}
